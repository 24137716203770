<template>
  <div
    :class="$style.root"
    :data-has-loaded="hasLoaded ? '' : null"
  >
    <mux-player
      theme="minimal"
      :class="$style.player"
      :playback-id="video?.playback_id"
      streamType="on-demand"

      playsinline

      :poster="poster"

      ref="videoTarget"
      @click="hasPlayed = true"
    />
  </div>
</template>

<script setup lang="ts">
import "@mux/mux-player";
import "@mux/mux-player/themes/minimal"

const videoTarget = ref(null)
const hasPlayed = ref(false)
const hasLoaded = ref(false)

const props = defineProps({
  video: {
    type: Object
  },
  poster: {
    type: String
  }
})

// 1
const { stop } = useIntersectionObserver(
  videoTarget,
  ([{ isIntersecting }], observerElement) => {
    if (!isIntersecting) { videoTarget?.value?.pause() }
    if (hasLoaded.value) { return }
    setTimeout(() => {
      hasLoaded.value = true
    }, 750)
  }
)
</script>

<style module>
.root {
  composes: reset-focus from global;

  aspect-ratio: var(--aspect-ratio);

  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  --dialog: none;
  --loading-indicator: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --rendition-selectmenu: none;
  /* --center-controls: none; */
  --controls-backdrop-color: none;

  --media-primary-color: var(--color--white);
  --media-range-track-color: var(--color--white);

  --media-accent-color: var(--color--gray);

  --media-font-size: calc(var(--font-small--font-size) * .85);
  --media-font-family: 'NeutralStd';
  --media-text-content-height: 10px;
}

.root:not([data-has-loaded]) .player {
  visibility: hidden;
}

.player {
  width: 100%;
  height: 100%;
}

.root mux-player::part(bottom play button),
.root mux-player::part(bottom time display),
.root mux-player::part(bottom mute button),
.root mux-player::part(bottom fullscreen button) {
  transition: none;
  border-radius: 0;
}

.root mux-player::part(fullscreen button) {
  display: none;
}


.root mux-player::part(bottom play button),
.root mux-player::part(bottom time display),
.root mux-player::part(bottom mute button),
.root mux-player::part(bottom fullscreen button) {
  transition: none;
  border-radius: 0;
}
</style>
