<template>
  <div
    :class="$style.root"
    :style="includeAspectRatio ? `--aspect-ratio: ${aspectRatioAndOrientation?.aspectRatio};` : null"
    :data-orientation="aspectRatioAndOrientation?.orientation"
    :data-lightbox-video-id="video?.id"
  >
    <div :class="$style.videoContainer">
      <transition name="button-default">
        <button
          v-if="includeZoomButton"
          :class="$style.button"
          @click="setQueryParams"
        >
          <SvgoPlus :class="$style.svg" />
        </button>
      </transition>

      <client-only>
        <core-mux-video-default-component
          v-if="includeControls === false"
          :video="video"
          :poster="poster"
          :key="`default-${route?.path}`"
        />
        <core-mux-video-player-component
          v-else
          :video="video"
          :poster="poster"
          :key="`player-${route?.path}`"
        />
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()

const props = defineProps({
  video: {
    type: Object
  },
  includeControls: {
    type: Boolean,
    default: false
  },
  includeAspectRatio: {
    type: Boolean,
    default: true
  },
  includeZoomButton: {
    type: Boolean,
    default: true
  }
})

const aspectRatioAndOrientation = getMuxOrientation(props?.video)

const posterWidth = 1000
const posterHeight = Math.round(posterWidth / (aspectRatioAndOrientation?.width / aspectRatioAndOrientation?.height))
const poster = computed(() => {
  return `https://image.mux.com/${props?.video?.playback_id}/thumbnail.png?width=${posterWidth}&height=${posterHeight}&fit_mode=smartcrop&time=10`
})

const setQueryParams = () => {
  router.push({ query: { ...route?.query, 'lightbox-video-id': props?.video?.id } })
}
</script>

<style module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.root[data-orientation="portrait"] .videoContainer {
  width: 50%;
}

.videoContainer {
  aspect-ratio: var(--aspect-ratio);
  overflow: hidden;

  width: 100%;
  height: 100%;
  flex: 1;
}

.button {
  composes: reset-button button-round button-round-small button-round-transparent from global;
  position: absolute;
  z-index: 1;

  top: var(--unit--vertical);
  right: var(--unit--horizontal);

  @media (max-width: $breakpoint-xs) {
    display: none;
  }
}

.svg {
  composes: button-svg from global;
}
</style>
