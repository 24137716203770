export const getMuxOrientation = (video: Object) => {
  if (!video?.aspect_ratio) return null
  if (!video?.aspect_ratio?.includes(':')) return '16/9'
  const width = Number(video?.aspect_ratio?.split(':')[0])
  const height = Number(video?.aspect_ratio?.split(':')[1])
  if (width > height) {
    return {
      aspectRatio: `${width}/${height}`,
      orientation: 'landscape',
      width: width,
      height: height
    }
  } else if (width < height) {
    return {
      aspectRatio: `${width}/${height}`,
      orientation: 'portrait',
      width: width,

    }
  } else {
    return {
      aspectRatio: 1,
      orientation: 'square',
      width: width,
    }
  }
}
